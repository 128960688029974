<template>
  <div class="flex flex-col h-full mx-auto bg-ld-background overflow-hidden">
    <SafeAreaTopSpacer />
    <AppBar
      :title="appBarTitle"
      @back="onBack"
    >
      <template #actions>
        <Button
          v-if="data.package"
          size="small"
          :disabledMsg="
            _t('learn.card_require', { count: _global.minCardCountToLearn })
          "
          :disabled="data.package.cardCount < _global.minCardCountToLearn"
          :label="_t('learn.to_atlas')"
          @click="onChallenge"
        ></Button>
      </template>
    </AppBar>

    <UnpublishedPackage
      v-if="data.package != null && isPackageUpdateUnreleased(data.package)"
      :package="data.package"
      @on-update="onUpdate"
    />

    <div class="flex flex-col flex-1 relative overflow-hidden">
      <Loading
        v-if="data.packageLoading || data.cardsLoading"
        class="h-full"
      />

      <template v-else-if="data.package">
        <SlickList
          :list="data.cards"
          axis="y"
          class="flex-1 flex flex-col overflow-hidden"
          useDragHandle
          @sortEnd="onCardsSortEnd"
        >
          <CardList
            ref="cardList"
            class="flex-1 overflow-hidden"
            :package="data.package"
            :chapterId="chapterId"
            :cards="data.cards"
            @chapterClick="onChapterClick"
            @createCard="onCardCreateClick"
            @ai-generate="onAiGenerate"
          >
            <template #card="{ cardRes, noteIndex: cardIndex }">
              <SlickItem :index="cardIndex">
                <div
                  v-if="cardIndex > 0"
                  class="h-12px"
                ></div>

                <CardPad
                  v-if="
                    isOwner &&
                    (data.cardFocus === cardIndex || invalidCards[cardRes.id])
                  "
                  :canDelete="canCardDelete(cardRes)"
                  :canSwitchCardType="data.package.cardType == null"
                  :packageId="data.package.id"
                  :cardDraft="cardRes"
                  :cards="data.cards"
                  :class="{
                    'card-focus': data.cardFocus === cardIndex,
                  }"
                  @create-after="onCreateCard(cardIndex + 1)"
                  @create-before="onCreateCard(cardIndex)"
                  @copy="onCardCopy(chapterId, cardRes.id, cardRes)"
                  @delete="onCardDraftDelete(cardRes, cardIndex)"
                  @update="onCardDraftUpdate(cardRes, cardIndex, $event)"
                  @add-illustration="
                    onIllustrationAdd(cardRes, cardIndex, $event)
                  "
                  @remove-illustration="onIllustrationRemove(cardRes)"
                  @change-card-type="defaultCardType = $event"
                  @focus="onCardEditorFocus"
                >
                  <template
                    v-if="activeCardResponse && activeCard"
                    #footer
                  >
                    <DistractorPanel
                      v-if="activeCard.type === CardType.CLOZE"
                      :cardId="activeCardResponse.id"
                      :card="activeCard"
                      class="bg-gray-100 rounded-b-8px"
                      @focus="onCardEditorFocus"
                    ></DistractorPanel>

                    <MCQEditPanel
                      v-if="activeCard.type === CardType.MCQ"
                      :key="activeCardResponse.id"
                      :cardId="activeCardResponse.id"
                      :card="activeCard"
                      class="bg-gray-100 rounded-b-8px"
                      @focus="onCardEditorFocus"
                      @update="onMCQCardUpdate"
                    />
                  </template>
                </CardPad>

                <CardBrowserPad
                  v-else
                  :key="cardRes.id"
                  :cardResponse="cardRes"
                  :learn-count="data.cardLearnCountMap[cardRes.id]"
                  :class="[
                    {
                      'card-focus': data.cardFocus === cardIndex,
                      'g-card-highlight': data.highlightCards[cardRes.id],
                    },
                  ]"
                  @click="onCardSelect(cardIndex)"
                />
              </SlickItem>
            </template>
          </CardList>
        </SlickList>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Code } from '@/api/code'
import {
  fetchCards,
  fetchPackageById,
  type Package,
  type CardResponse,
  type ChapterItem,
  type PackageBasic,
  createCard,
  CardCreatedType,
  deleteCard,
  CardUpdatedType,
  updateCard,
  moveCard,
  fetchCardsStats,
  CardTypeName,
} from '@/api/package-source'
import Loading from '@/components/Loading.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { onUnmounted } from 'vue'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CardList from '@/components/package/CardList.vue'
import UnpublishedPackage from '@/components/UnpublishedPackageHeader.vue'
import { isPackageUpdateUnreleased } from '@/utils/package'
import CardBrowserPad from '@/components/package/CardBrowserPad.vue'
import CardPad from '@/components/package/CardPad/CardPad.vue'
import { SlickList, SlickItem } from 'vue-slicksort'
import {
  getCardTitleForDialog,
  newClozeCard,
  newMCQCard,
  newWordCard,
  updateClozeCardContentDistractors,
  validateCard,
  type CardDraft,
} from '@/utils/card'
import { CardType, type Card, type ClozeCard, type MCQCard } from '@/types/core'
import dayjs from 'dayjs'
import { debounce, mapValues } from 'lodash-es'
import type { DragPosition } from '@/components/Tree/Tree.vue'
import bus, { BusEvent } from '@/bus/bus'
import AICardGenerate from '@/components/package/AICardGenerate.vue/AICardGenerate.vue'
import DistractorPanel, {
  type ClozeGroup,
} from '@/components/DistractorPanel/DistractorPanel.vue'
import MCQEditPanel from '@/components/MCQEditPanel.vue'
import { convertCardNameToCardType } from '@/shared'

const store = useCommonStore()
const route = useRoute()
const router = useRouter()
const onBack = () => {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({
      name: 'shelf',
    })
  }
}

const packageId = Number(route.params.id)
const chapterId = computed(
  () => (route.query.chapterId as string | undefined) ?? 'root'
)

const isOwner = computed(() => data.package?.owned != null)

const defaultCardType = ref<CardType>(CardType.CLOZE)

const appBarTitle = computed(() => {
  if (data.package) {
    if (chapterId.value === 'root') return data.package.name
    return data.package.chapters[chapterId.value].title
  }
  return ''
})

const activeCardResponse = computed(() => {
  if (data.cardFocus < 0) return null

  return data.cards[data.cardFocus]
})

const activeCard = computed(() => {
  if (activeCardResponse.value != null) {
    return JSON.parse(activeCardResponse.value.content) as Card
  }

  return null
})

const data = reactive({
  package: undefined as Package | undefined,
  packageLoading: true,
  cards: [] as CardDraft[],
  cardLearnCountMap: {} as Record<number, number>,
  cardsLoading: true,
  cardFocus: -1,

  highlightCards: {} as Record<number, boolean>,
})

// 如果编辑的卡片内容不合法，需要保持编辑器状态
// 这里用于存储哪些卡片未保存下来
const invalidCards = ref<Record<number, boolean>>({})

function canCardDelete(card: CardResponse) {
  // 已保存的卡片可以直接删除
  // 非最后一张草稿卡可以删除
  return card.id >= 0 || data.cards.length > 1
}

async function onCardCopy(
  chapterId: string,
  cardId: number,
  card: CardResponse
): Promise<CardResponse | undefined> {
  if (data.package == null) return
  if (cardId < 0) {
    _message.info(_t('cardview.save_tip1'))
    return
  }

  const content = JSON.parse(card.content)

  return createCard({
    packageId: data.package.id,
    chapterId,
    content,
    afterCardId: cardId,
    createdType: CardCreatedType.DUPLICATE,
  }).then(res => {
    if (res.code === 0) {
      const index = data.cards.findIndex(item => item.id === cardId)

      if (index > -1) {
        data.cards.splice(index + 1, 0, {
          renderId: getCardRenderId(),
          ...res.data,
        })
      }

      data.package!.cardCount += 1
      return res.data
    } else {
      _message.info(res.message)
    }
  })
}

let isDeleteConfirmOpen = false
async function onCardDraftDelete(cardRes: CardResponse, index: number) {
  if (isDeleteConfirmOpen) return

  isDeleteConfirmOpen = true

  const needConfirm = cardRes.id >= 0

  function onDelete() {
    if (cardRes.id > 0) {
      onCardDelete(chapterId.value, cardRes.id)
    } else {
      data.cards.splice(index, 1)
    }
  }

  if (needConfirm) {
    await _confirm({
      scene: 'warn',
      title: _t('package.delete_card_title', {
        title: getCardTitleForDialog(cardRes.content),
      }),
      content: _t('common.delete_tip'),
      primaryText: 'common.not_yet',
      secondaryText: 'common.delete',
      onSecondaryClick(resolve) {
        resolve(true)
        onDelete()
      },
    })
    isDeleteConfirmOpen = false
  } else {
    onDelete()
  }
}

async function onCardDelete(chapterId: string, cardId: number) {
  const card = data.cards.find(item => item.id === cardId)

  if (card == null || data.package == null) return

  const res = await deleteCard(data.package.id, chapterId, Number(cardId))

  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  data.package!.cardCount -= 1

  const index = data.cards.findIndex(item => item.id === cardId)

  if (index > -1) {
    data.cards.splice(index, 1)
    _message.info(_t('common.delete_success'))
    return true
  }
}

// 每次新建一个卡片草稿时的 id，新增时自增
let cardRenderId = 0
function getCardRenderId(): number {
  cardRenderId++

  return cardRenderId
}

function onCreateCard(index: number) {
  if (!isOwner.value) return

  let card: Card

  switch (defaultCardType.value) {
    case CardType.CLOZE:
      card = newClozeCard()
      break
    case CardType.EN_WORD:
      card = newWordCard()
      break
    case CardType.MCQ:
      card = newMCQCard()
      break
    default:
      card = newClozeCard()
  }

  const renderId = getCardRenderId()
  const newCardDraft: CardDraft = {
    renderId,
    id: -renderId,
    content: JSON.stringify(card),
    contentHash: 0,
    createdType: CardCreatedType.NORMAL,
    updatedType: CardUpdatedType.NORMAL,
    createdAt: dayjs().format('YYYY-DD-MM'),
    updatedAt: dayjs().format('YYYY-DD-MM'),
    authorId: '',
  }

  cardRenderId++
  data.cards.splice(index, 0, newCardDraft)
  onCardSelect(index)
  scrollToCardListBottom()
}

function onCardDraftUpdate(
  cardRes: CardResponse,
  noteIndex: number,
  newCard: Card
) {
  cardRes.content = JSON.stringify(newCard)

  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = noteIndex - 1; index >= 0; index--) {
      if (data.cards[index].id > 0) {
        afterCardId = data.cards[index].id
        break
      }
    }

    for (let index = noteIndex + 1; index < data.cards.length; index++) {
      if (data.cards[index].id > 0) {
        beforeCardId = data.cards[index].id
        break
      }
    }

    saveCreatedCard(cardRes, newCard, afterCardId, beforeCardId)
  } else {
    saveUpdatedCard(cardRes.id, newCard)
  }
}

const saveUpdatedCard = debounce((cardId: number, content: Card) => {
  if (validateCard(content)) {
    invalidCards.value[cardId] = true
    return
  }

  invalidCards.value[cardId] = false
  return updateCard(cardId, content).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }
  })
}, 500)

const saveCreatedCard = debounce(
  (
    cardRes: CardResponse,
    card: Card,
    afterCardId?: number,
    beforeCardId?: number
  ) => {
    if (data.package == null) return

    if (validateCard(card)) {
      invalidCards.value[cardRes.id] = true
      return
    }

    createCard({
      packageId: data.package.id,
      chapterId: chapterId.value,
      content: card,
      afterCardId: afterCardId,
      beforeCardId: beforeCardId,
      createdType: CardCreatedType.NORMAL,
    }).then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      data.package!.cardCount += 1
      cardRes.id = res.data.id
      cardRes.content = res.data.content
    })
  },
  500
)

function onIllustrationAdd(
  cardRes: CardResponse,
  cardIndex: number,
  assetId: string
) {
  if (!_global.isInsideApp) {
    showAppDownloadDialog()
    return
  }

  const cardContent = JSON.parse(cardRes.content) as Card
  const card = {
    ...cardContent,
    illustration: assetId,
  } as Card
  cardRes.content = JSON.stringify(card)

  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = cardIndex - 1; index >= 0; index--) {
      if (data.cards[index].id > 0) {
        afterCardId = data.cards[index].id
        break
      }
    }

    for (let index = cardIndex + 1; index < data.cards.length; index++) {
      if (data.cards[index].id > 0) {
        beforeCardId = data.cards[index].id
        break
      }
    }

    saveCreatedCard(cardRes, card, afterCardId, beforeCardId)
    return
  }

  saveUpdatedCard(cardRes.id, {
    ...cardContent,
    illustration: assetId,
  })
}

function onIllustrationRemove(cardRes: CardResponse) {
  if (!_global.isInsideApp) {
    showAppDownloadDialog()
    return
  }

  const cardContent = JSON.parse(cardRes.content) as Card

  const newContent = {
    ...cardContent,
  }
  delete newContent.illustration
  cardRes.content = JSON.stringify(newContent)

  if (cardRes.id < 0) return
  saveUpdatedCard(cardRes.id, newContent)
}

function onCardsSortEnd({
  newIndex,
  oldIndex,
}: {
  newIndex: number
  oldIndex: number
}) {
  if (newIndex === oldIndex) return
  const focusedCardId = data.cards[data.cardFocus].id

  const sourceCard = data.cards[oldIndex]

  data.cards.splice(oldIndex, 1)
  data.cards.splice(newIndex, 0, sourceCard)

  // 如果移动的是一张草稿卡，则不需要调用接口，不影响
  if (sourceCard.id < 0) {
    return
  }

  const beforeCardId = data.cards[newIndex + 1]?.id

  if (beforeCardId != null) {
    moveCardPad(sourceCard.id, beforeCardId, 'before')
  } else {
    moveCardPad(sourceCard.id, beforeCardId, 'bottom')
  }

  data.cardFocus = data.cards.findIndex(item => item.id === focusedCardId)
}

async function moveCardPad(
  sourceCardId: number,
  targetCardId?: number,
  position?: DragPosition
) {
  if (data.package == null) return

  await moveCard({
    sourcePkgId: data.package.id,
    cardId: sourceCardId,
    beforeCardId: position === 'bottom' ? undefined : targetCardId,
    sourceChapterId: chapterId.value,
    targetChapterId: chapterId.value,
  })
}

async function fetchPackage() {
  data.packageLoading = true

  try {
    const res = await fetchPackageById(packageId)

    if (res.code === Code.PackageNotFound) {
      router.replace({
        name: '404',
      })
      return
    }

    data.package = res.data

    defaultCardType.value = convertCardNameToCardType(
      data.package.owned?.defaultCardType ??
        data.package.cardType ??
        CardTypeName.CLOZE
    )
  } finally {
    data.packageLoading = false
  }
}
const cardListCache = ref<Record<string, CardResponse[]>>({})

async function fetchCardList(chapterId: string) {
  if (chapterId == null) return []

  try {
    if (cardListCache.value[chapterId] != null) {
      data.cards = cardListCache.value[chapterId].map(card => {
        return {
          renderId: getCardRenderId(),
          ...card,
        }
      })
    } else {
      data.cardsLoading = true
      data.cards = (await fetchCards(packageId, chapterId)).data.cards.map(
        card => {
          return {
            renderId: getCardRenderId(),
            ...card,
          }
        }
      )
      cardListCache.value[chapterId] = data.cards
      data.cards.forEach(c => {
        store.setCardResponseCache(c.id, c)
      })
    }

    fetchCardsLearnCount(data.cards.map(item => item.id))
  } finally {
    data.cardsLoading = false
  }
}

function fetchCardsLearnCount(cardIds: number[]) {
  if (cardIds.length === 0) return

  fetchCardsStats(cardIds).then(res => {
    data.cardLearnCountMap = mapValues(res.cardStatsMap, v => v.learnTimes)
  })
}

function onChapterClick(chapter: ChapterItem) {
  router.push({
    query: {
      ...route.query,
      chapterId: chapter.id,
    },
  })
  fetchCardList(String(chapter.id))
}

function onRouteChange() {
  fetchCardList(chapterId.value)
}

function onUpdate(pkg: PackageBasic) {
  if (data.package) {
    Object.assign(data.package, pkg)
  } else {
    fetchPackage()
  }
}

onMounted(() => {
  window.addEventListener('popstate', onRouteChange)
  // 移动端这里用了 bus 来监听 ai 生成结束，因为如果切换了卡片，distractorPanel 会被卸载导致
  // update 事件拿不到，所以生成的过程中就不能切换卡片了。用 bus 实现可以切换卡片
  bus.on(BusEvent.AiDistractorsGenerated, onCurrentCardContentUpdate)
})
onUnmounted(() => {
  window.removeEventListener('popstate', onRouteChange)
  bus.off(BusEvent.AiDistractorsGenerated, onCurrentCardContentUpdate)
})

// 如果这个卡包是空的，则新建一张无法删除的草稿卡片
function createDraftCards() {
  if (data.package?.cardCount === 0) {
    onCreateCard(0)
  }
}

onInit(async () => {
  data.packageLoading = true
  data.cardsLoading = true
  await fetchPackage()
  if (data.package == null) return
  await fetchCardList(chapterId.value)

  watch(
    () => [data.cards.length, data.package?.cardCount],
    ([cardsLength, cardCount]) => {
      if (cardsLength === 0 && cardCount === 0) {
        createDraftCards()
      }
    },
    { immediate: true }
  )
})

function onCardSelect(index: number) {
  data.cardFocus = index
}

function highlightCard(id: number) {
  data.highlightCards[id] = true
  setTimeout(() => {
    data.highlightCards[id] = false
  }, 1000)
}

function onCurrentCardContentUpdate(
  cardId: number,
  group: ClozeGroup,
  distractors: string[]
) {
  const index = data.cards.findIndex(item => item.id === cardId)

  if (index < 0) return

  const cardRes = data.cards[index]

  const card = JSON.parse(cardRes.content) as ClozeCard
  const newContent = updateClozeCardContentDistractors(card, group, distractors)
  const newCard = {
    ...card,
    content: newContent,
  }
  data.cards.splice(index, 1, {
    ...cardRes,
    content: JSON.stringify(newCard),
  })
  onCardDraftUpdate(cardRes, index, newCard)
}

function onMCQCardUpdate(cardId: number, card: MCQCard) {
  const index = data.cards.findIndex(item => item.id === cardId)

  if (index < 0) return

  const cardRes = data.cards[index]
  data.cards.splice(index, 1, {
    ...cardRes,
    content: JSON.stringify(card),
  })
  onCardDraftUpdate(cardRes, index, card)
}

function onAiGenerate() {
  if (!_global.isInsideApp) {
    showAppDownloadDialog()
    return
  }

  if (data.package == null) return

  _openDialog(AICardGenerate, {
    title: _t('package.ai_create_card'),
    rootClass: 'g-dialog',
    props: {
      packageId: data.package.id,
      chapterId: chapterId.value,
      cardType: data.package.cardType,
      onCardsCreated(cards: CardResponse[]) {
        cards.forEach(card => {
          data.cards.push({
            renderId: getCardRenderId(),
            ...card,
          })
        })

        cards.forEach(card => highlightCard(card.id))
        const firstCard = cards[0]
        if (firstCard) {
          onCardSelect(firstCard.id)
          scrollToCardListBottom()
        }
      },
    },
    dialog: {
      pt: {
        content: {
          class: 'p-4 bg-ld-background',
        },
      },
    },
  })
}

function onCardCreateClick() {
  // if (!_global.isInsideApp) {
  //   showAppDownloadDialog()
  // }

  onCreateCard(data.cards.length)
}

function showAppDownloadDialog() {
  _confirm({
    scene: 'warn',
    icon: {
      type: 'svg',
      name: 'ld-avatar',
    },
    content: _t('app.download_tip2', { link: _global.pcLink }),
    primaryText: 'app.download_tip1',
    secondaryText: 'common.not_yet',
    async onPrimaryClick(resolve) {
      router.push({ name: 'app-download' })
      resolve(true)
    },
  })
}

function onCardEditorFocus() {
  if (!_global.isInsideApp) {
    // 如果不在 app 中，则需要 blur 掉聚焦的输入框，不让输入
    ;(document.activeElement as any).blur?.()

    showAppDownloadDialog()
  }
}

const cardList = ref()
function scrollToCardListBottom() {
  nextTick(() => {
    if (cardList.value) {
      cardList.value.scrollToBottom()
    }
  })
}

function onChallenge() {
  router.push({
    name: 'atlas',
    query: {
      pkgId: data.package?.id,
    },
  })
}
</script>

<style scoped>
.card-focus {
  border-color: var(--ld-brand-500);
}
</style>
