<template>
  <RatioSpacedContainer
    :top="1"
    :bottom="1"
    class="h-ld-screen px-4"
  >
    <Icon
      name="ld-avatar"
      class="w-92px"
    />

    <div class="text-17px leading-[1.8] tracking-0.5px my-4">
      {{ _t('landing.msg1') }}
    </div>

    <Button
      :label="_t('app.download')"
      class="mt-22 w-full"
      @click="onAppStoreLaunch"
    ></Button>

    <Button
      scene="secondary"
      class="mt-4 w-full"
      @click="onAppLaunch"
    >
      <span class="flex-1 text-center text-ld-brand-500">
        {{ _t('app.open_in_app') }}
      </span>
    </Button>
  </RatioSpacedContainer>
  <div
    v-if="showBrowserOpenMask"
    class="fixed h-full w-full left-0 top-0 bg-blackMask"
    @click="showBrowserOpenMask = false"
  >
    <Img
      name="app-download-safari"
      class="w-314px !absolute top-212px left-50% translate-x--1/2"
    />
    <Img
      name="app-download-step"
      class="w-178px !absolute right-2 top-0"
    />
  </div>
</template>
<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import { ref } from 'vue'

useHead({
  title: _t('app.meta'),
  meta: [
    {
      name: 'description',
      content: _t('landing.msg1'),
    },
  ],
})

const APP_SCHEMA_URL = 'lingduck://'
const PKG_NAME = 'ai.qianmo.lingduck'
const IOS_APP_STORE_LINK =
  'https://apps.apple.com/app/apple-store/id6736635589?pt=122930205&ct=wap&mt=8'
const APK_DOWNLOAD_LINK = 'https://cdn.lingduck.cn/sys/app/lingduck-latest.apk'

const showBrowserOpenMask = ref(false)
function openLink(link: string) {
  // 由于微信内禁用 SchemaURL，所以这里直接展示浏览器打开的提示
  if (_global.isInsideWechat) {
    showBrowserOpenMask.value = true
  }

  window.open(link, '_self')
}

// 根据设备的品牌跳转到不同的应用商店
function onAppStoreLaunch() {
  const userAgent = navigator.userAgent.toLowerCase()
  const isMac = userAgent.match(/macintosh/i)
  const isIphone = userAgent.match(/(iphone|ipad|ipod)/i)
  const isHuawei = userAgent.match(/huawei/i)
  const isHonor = userAgent.match(/honor/i)
  const isOppo = userAgent.match(/oppo/i)
  const isOppoR15 = userAgent.match(/pacm00/i)
  const isVivo = userAgent.match(/vivo/i)
  const isXiaomi = userAgent.match(/mi\s/i)
  const isXIAOMI = userAgent.match(/xiaomi/i)
  const isXiaomi2s = userAgent.match(/mix\s/i)
  const isRedmi = userAgent.match(/redmi/i)

  if (isIphone || isMac) {
    openLink(IOS_APP_STORE_LINK)
  } else if (isHuawei || isHonor) {
    openLink(`appmarket://details?id=${PKG_NAME}`)
  } else if (isOppo || isOppoR15) {
    openLink(`oppomarket://details?packagename=${PKG_NAME}`)
  } else if (isVivo) {
    openLink(`vivomarket://details?id=${PKG_NAME}`)
  } else if (isXiaomi || isRedmi || isXiaomi2s || isXIAOMI) {
    openLink(`mimarket://details?id=${PKG_NAME}`)
  } else {
    openLink(APK_DOWNLOAD_LINK)
  }
}

function onAppLaunch() {
  openLink(APP_SCHEMA_URL)
}

// 页面加载的时候先尝试启动一次 App，以及打开对应的应用商店
onInit(() => {
  onAppLaunch()
  onAppStoreLaunch()
})
</script>
<style scoped></style>
